// 
import { observer } from "mobx-react-lite"
import React, { FC, useCallback, useEffect, useReducer, useState, ActivityIndicator, useRef } from "react"
import { ImageStyle, Platform, ScrollView, TextStyle, ViewStyle, SafeAreaView, FlatList, Dimensions, DeviceEventEmitter } from "react-native"

import { api } from "../../../services/api"
import { colors, spacing } from "../../../theme"
import { View, Text, TextField, Card, Checkbox, DateTimePicker, Picker, SegmentedControl, Assets, Spacings, Colors, BorderRadiuses, Timeline, GridList, GridView, Modal, SortableList, SortableGridList, Image, Button, TabController, FloatingButton, TouchableOpacity, Dialog, PanningProvider, Chip, ActionSheet } from 'react-native-ui-lib';
import DynamicForm, { CustomDatePicker, CustomDateTimePicker, CustomModal, CustomNumberInput, CustomSelect, CustomTextField, CustomTimePicker, FiltersComponent, SecondaryButton } from "../../../mycomponents/DynamicForm"
import DynamicTable from "../../../mycomponents/DynamicTable"
import { useNavigation } from '@react-navigation/native';
import Svg, { Path, G, Circle } from "react-native-svg";
import { LinearGradient } from 'expo-linear-gradient'
import { BoxShadow } from 'react-native-shadow'
import { useStores } from "../../../models";
import FormScreen from "./FormScreen"
import { useMatch, useNavigate, useRoutes, useSearchParams } from "react-router-dom";
import { Sidebar } from "../../../mycomponents/SideBar"
import { Dropdown } from "antd"
import { Icon } from "react-native-eva-icons"
import { HeaderWeb } from "../../../mycomponents/HeaderWeb"
import LayoutScreen from "./LayoutScreen"
import { FABComponent } from "../../../mycomponents/FABComponent"
import dayjs from "dayjs"
import { GlobalMasterStore } from "../../../models/PermissionStore"
import moment from "moment"
import ListingScreen from "./ListingScreen"

const LateCountFunction = (props) => {
  const [showModal, setShowModal] = useState(false);

  return <View>
    <TouchableOpacity onPress={() => {
      setShowModal(true);
    }}>
      <Text
        style={[
          { maxWidth: '100%' },
          {
            fontFamily: 'SourceSansProSemiBold',
            color: 'blue',
            textDecorationColor: 'blue',
            textDecorationLine: 'underline',
            paddingHorizontal: 5,
          }
        ]} ellipsizeMode={'tail'} numberOfLines={1}>
        {props.value}
      </Text>
    </TouchableOpacity>

    <CustomModal
      visible={showModal}
      onClose={() => {
        setShowModal(false);
      }}
      width={'80%'}
    >
      <ReportScreenSingle
        moduleName={"AgentLateKPIDetails"}
        hideWrapper={true}
        noMono={true}
        hideHeader={true}
        params={{
          quickFilterKey: 'employee_id',
          quickFilterValue: props.row?.employee_id,
          hideQuickFilter: true,
          tabFilter: { 'startDate': props.startDate, 'endDate': props.endDate, 'basis': props.row?.basis }
        }}
        route={{
          params: {
            quickFilterKey: 'employee_id',
            quickFilterValue: props.row?.employee_id,
            hideQuickFilter: true,
            name: "AgentLateKPIDetails",
            tabFilter: { 'startDate': props.startDate, 'endDate': props.endDate, 'basis': props.row?.basis }
          }
        }}
      />
    </CustomModal>
  </View>;
}

const AbsentCountFunction = (props) => {
  const [showModal, setShowModal] = useState(false);

  return <View>
    <TouchableOpacity onPress={() => {
      setShowModal(true);
    }}>
      <Text
        style={[
          { maxWidth: '100%' },
          {
            fontFamily: 'SourceSansProSemiBold',
            color: 'blue',
            textDecorationColor: 'blue',
            textDecorationLine: 'underline',
            paddingHorizontal: 5,
          }
        ]} ellipsizeMode={'tail'} numberOfLines={1}>
        {props.value}
      </Text>
    </TouchableOpacity>

    <CustomModal
      visible={showModal}
      onClose={() => {
        setShowModal(false);
      }}
      width={'80%'}
    >
      <ReportScreenSingle
        moduleName={"AgentAbsentKPIDetails"}
        hideWrapper={true}
        noMono={true}
        hideHeader={true}
        params={{
          quickFilterKey: 'employee_id',
          quickFilterValue: props.row?.employee_id,
          hideQuickFilter: true,
          tabFilter: { 'startDate': props.startDate, 'endDate': props.endDate, 'basis': props.row?.basis }
        }}
        route={{
          params: {
            quickFilterKey: 'employee_id',
            quickFilterValue: props.row?.employee_id,
            hideQuickFilter: true,
            name: "AgentAbsentKPIDetails",
            tabFilter: { 'startDate': props.startDate, 'endDate': props.endDate, 'basis': props.row?.basis }
          }
        }}
      />
    </CustomModal>
  </View>;
}

const MeetingDetailsFunction = (props) => {
  const [showModal, setShowModal] = useState(false);

  return <View>
    <TouchableOpacity onPress={() => {
      setShowModal(true);
    }}>
      <Text
        style={[
          { maxWidth: '100%' },
          {
            fontFamily: 'SourceSansProSemiBold',
            color: 'blue',
            textDecorationColor: 'blue',
            textDecorationLine: 'underline',
            paddingHorizontal: 5,
          }
        ]} ellipsizeMode={'tail'} numberOfLines={1}>
        {props.value}
      </Text>
    </TouchableOpacity>

    <CustomModal
      visible={showModal}
      onClose={() => {
        setShowModal(false);
      }}
      width={'80%'}
    >

{/* 'employee_id': employee_id ?? null,
                        'meeting.date': {
                            $gte: dates[0],
                            $lte: dates[1],
                        } */}
      {/* <ReportScreenSingle
        moduleName={"MeetingAttendances"}
        hideWrapper={true}
        noMono={true}
        hideHeader={true}
        params={{
          quickFilterKey: 'employee_id',
          quickFilterValue: props.row?.employee_id,
          hideQuickFilter: true,
          tabFilter: { 'startDate': props.startDate, 'endDate': props.endDate, 'basis': props.row?.basis }
        }}
        route={{
          params: {
            quickFilterKey: 'employee_id',
            quickFilterValue: props.row?.employee_id,
            hideQuickFilter: true,
            name: "AgentLateKPIDetails",
            tabFilter: { 'startDate': props.startDate, 'endDate': props.endDate, 'basis': props.row?.basis }
          }
        }}
      /> */}


          <ListingScreen
                {...{
                    moduleName: 'MeetingAttendances',
                    hideWrapper: true,
                    extraFilters: props.row?.particulars == 'Late' ? {
                      'employee_id': props.row?.employee_id ?? null,
                      'meeting.date': {
                          $gte: props.row?.startDate,
                          $lte: props.row?.endDate > new Date() ? new Date() : props.row?.endDate,
                      },
                      'status': 'Present',
                      'late_check_in_timing': {
                        $gt: 0
                      }
                  } : {
                        'employee_id': props.row?.employee_id ?? null,
                        'meeting.date': {
                            $gte: props.row?.startDate,
                            $lte: props.row?.endDate > new Date() ? new Date() : props.row?.endDate,
                        },
                        'status': props.row?.particulars
                    },
                    extraBody:{
                        no_generate: true
                    },
                    hideViews: true,
                    hideMainHeader: true,
                    showExports: true
                }}
                title={" "}
                hiddenColumns={["employee.name", "employee.name"]}
                disabledClone={true}
                disableDelete={true}
                default_values={{

                }} />
    </CustomModal>
  </View>;
}

const ReportScreenSingle = (props) => {
  let navigation = useNavigation();
  let navigate = null;

  let route = props?.route;
  let queryParams = { ...(props?.route?.params || {}) };

  if (Platform.OS === 'web') {
    navigate = useNavigate();
    let path = window?.location?.pathname;
    route = { name: path.split("/")[2] || 'Items' }

    const params = window.location.search.substring(1).split('&')
    params.map((param) => {
      const [key, value] = param.split('=')
      if (key) {
        queryParams[key] = value;
      }
    })
  }

  const navigationFn = (path, params) => {
    if (Platform.OS === 'web') {
      let paramsString = "";
      if (params) {
        paramsString = "?" + Object.keys(params).map(key => key + '=' + params[key]).join('&');
      }
      navigate("/" + path + paramsString);
    } else {
      if (path.includes("report/")) {
        navigation.push("report", {
          ...params,
          name: path.replace("report/", ""),
        });
      } else {
        navigation.push(path, params);
      }
    }
  };

  const [rows, setRows] = useState([]);
  const [total, setTotal] = useState(0);
  const [allColumns, setAllColumns] = useState([]);
  const [selectedColumns, setSelectedColumns] = useState([]);
  const [filters, setFilters] = useState([]);
  const [showFilter, setShowFilter] = useState(false);
  const [showExport, setShowExport] = useState(false);
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [sortColumn, setSortColumn] = useState('');
  const [sortDirection, setSortDirection] = useState('');
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [loading, setLoading] = useState(false);
  const [ids, setIds] = useState([]);
  const [groupBy, setGroupBy] = useState(null);
  const [groupByModal, setGroupByModal] = useState(false);
  const [search, setSearch] = useState('');
  const [filter, setFilter] = useState({
    field: null,
    label: null,
    operator: null,
    operator_label: null,
    operators: [],
    type: "text",
    value: null
  });

  const settingsMap = {


    // "Daily Activities By Groups",
    // "Daily Activities By Employees",
    // "Sales Activities By Groups",
    // "Sales Activities By Employees",
    "AgentLateKPIDetails": {
      showDateRange: false,
      customTitle: "Agent Late KPI Details"
    },
    "AgentAbsentKPIDetails": {
      showDateRange: false,
      customTitle: "Agent Absent KPI Details"
    },
    "DailyActivitiesByGroups": {
      showDateRange: true,
    },
    "DailyActivitiesByAgents": {
      showDateRange: true,
      customFilter: (props) => {
        const searchTimeOutRef = useRef(null);
        const [agentName, setAgentName] = useState(props.tabFilter.agent_name || null);

        useEffect(() => {
          if (agentName !== null && agentName != props.tabFilter.agent_name) {
            const obj = {
              ...props.tabFilter,
              agent_name: agentName
            };

            if (!agentName) {
              delete obj.agent_name;
            }

            if (searchTimeOutRef.current) {
              clearTimeout(searchTimeOutRef.current);
            }

            searchTimeOutRef.current = setTimeout(() => {
              props.setTabFilter(obj)
            }, 500);
          }
        }, [agentName])


        return <View row marginB-10>
          <View>
            <CustomTextField placeholder="Agent Name" value={agentName} onChange={(val) => {
              setAgentName(val);
            }} />
          </View>
          {/* <View marginL-10 style={{
            width: 200
          }}>
            <CustomSelect placeholder="Group" value={props.tabFilter.group_id} options={
              (props.createData?.Groups || []).map((group) => ({ ...group, label: group.name, value: group._id }))
            } onChange={(val) => {
              const obj = {
                ...props.tabFilter,
                group_id: val
              };

              if (!val) {
                delete obj.group_id;
              }
              props.setTabFilter(obj)
            }} />
          </View> */}

          <View marginL-10 style={{
            width: 200
          }}>
            <CustomSelect placeholder="Agency Teams" value={props.tabFilter.agency_team_id} options={
              (props.createData?.AgencyTeams || []).map((group) => ({ ...group, label: group.name, value: group._id }))
            } onChange={(val) => {
              const obj = {
                ...props.tabFilter,
                agency_team_id: val
              };

              if (!val) {
                delete obj.agency_team_id;
              }

              props.setTabFilter(obj)
            }} />
          </View>
        </View>
      }
    },
    "DailyActivitiesByAgentsDetail": {
      showDateRange: true,
    },
    "SalesActivitiesByGroups": {
      showDateRange: true,
      start_date: moment().startOf('year').toDate(),
      end_date: moment().endOf('year').toDate(),
    },
    "SalesActivitiesByAgents": {
      showDateRange: true,
      start_date: moment().startOf('year').toDate(),
      end_date: moment().endOf('year').toDate(),
      customFilter: (props) => {
        const searchTimeOutRef = useRef(null);
        const [agentName, setAgentName] = useState(props.tabFilter.agent_name || null);

        useEffect(() => {
          if (agentName !== null && agentName != props.tabFilter.agent_name) {
            const obj = {
              ...props.tabFilter,
              agent_name: agentName
            };

            if (!agentName) {
              delete obj.agent_name;
            }

            if (searchTimeOutRef.current) {
              clearTimeout(searchTimeOutRef.current);
            }

            searchTimeOutRef.current = setTimeout(() => {
              props.setTabFilter(obj)
            }, 500);
          }
        }, [agentName])


        return <View row marginB-10>
          <View>
            <CustomTextField placeholder="Agent Name" value={agentName} onChange={(val) => {
              setAgentName(val);
            }} />
          </View>
          {/* <View marginL-10 style={{
            width: 200
          }}>
            <CustomSelect placeholder="Group" value={props.tabFilter.group_id} options={
              (props.createData?.Groups || []).map((group) => ({ ...group, label: group.name, value: group._id }))
            } onChange={(val) => {
              const obj = {
                ...props.tabFilter,
                group_id: val
              };

              if (!val) {
                delete obj.group_id;
              }
              props.setTabFilter(obj)
            }} />
          </View> */}

          <View marginL-10 style={{
            width: 200
          }}>
            <CustomSelect placeholder="Agency Teams" value={props.tabFilter.agency_team_id} options={
              (props.createData?.AgencyTeams || []).map((group) => ({ ...group, label: group.name, value: group._id }))
            } onChange={(val) => {
              const obj = {
                ...props.tabFilter,
                agency_team_id: val
              };

              if (!val) {
                delete obj.agency_team_id;
              }

              props.setTabFilter(obj)
            }} />
          </View>
        </View>
      }
    },

    // "Agent KPI Details",
    // "Meeting Attendance KPI By Agents",
    // "Training Attendance KPI By Agents",
    // "Daily Activities KPI By Agents",

    "AgentKPIDetails": {
      showDateRange: true,
      customColumnRender: {
        late_count: (value, row, column) => {

          return <View left>
            <LateCountFunction value={value} row={row} column={column} startDate={startDate} endDate={endDate} />
          </View>
        },
        absent_count: (value, row, column) => {

          return <View left>
            <AbsentCountFunction value={value} row={row} column={column} startDate={startDate} endDate={endDate} />
          </View>
        },
      },
      customTitle: "Agent KPI Details"
    },
    "MeetingAttendanceKPIByAgents": {
      showDateRange: true,
      customFilter: (props) => {
        const searchTimeOutRef = useRef(null);
        const [agentName, setAgentName] = useState(props.tabFilter.agent_name || null);

        useEffect(() => {
          if (agentName !== null && agentName != props.tabFilter.agent_name) {
            const obj = {
              ...props.tabFilter,
              agent_name: agentName
            };

            if (!agentName) {
              delete obj.agent_name;
            }

            if (searchTimeOutRef.current) {
              clearTimeout(searchTimeOutRef.current);
            }

            searchTimeOutRef.current = setTimeout(() => {
              props.setTabFilter(obj)
            }, 500);
          }
        }, [agentName])


        return <View row marginB-10>
          <View>
            <CustomTextField placeholder="Agent Name" value={agentName} onChange={(val) => {
              setAgentName(val);
            }} />
          </View>
          <View marginL-10 style={{
            width: 200
          }}>
            <CustomSelect placeholder="Group" value={props.tabFilter.group_id} options={
              (props.createData?.Groups || []).map((group) => ({ ...group, label: group.name, value: group._id }))
            } onChange={(val) => {
              const obj = {
                ...props.tabFilter,
                group_id: val
              };

              if (!val) {
                delete obj.group_id;
              }
              props.setTabFilter(obj)
            }} />
          </View>

          <View marginL-10 style={{
            width: 200
          }}>
            <CustomSelect placeholder="Agency Teams" value={props.tabFilter.agency_team_id} options={
              (props.createData?.AgencyTeams || []).map((group) => ({ ...group, label: group.name, value: group._id }))
            } onChange={(val) => {
              const obj = {
                ...props.tabFilter,
                agency_team_id: val
              };

              if (!val) {
                delete obj.agency_team_id;
              }

              props.setTabFilter(obj)
            }} />
          </View>
        </View>
      },
      customTitle: "Meeting Attendance KPI By Agents"
    },
    "TrainingAttendanceKPIByAgents": {
      showDateRange: true,
      customFilter: (props) => {
        const searchTimeOutRef = useRef(null);
        const [agentName, setAgentName] = useState(props.tabFilter.agent_name || null);

        useEffect(() => {
          if (agentName !== null && agentName != props.tabFilter.agent_name) {
            const obj = {
              ...props.tabFilter,
              agent_name: agentName
            };

            if (!agentName) {
              delete obj.agent_name;
            }

            if (searchTimeOutRef.current) {
              clearTimeout(searchTimeOutRef.current);
            }

            searchTimeOutRef.current = setTimeout(() => {
              props.setTabFilter(obj)
            }, 500);
          }
        }, [agentName])


        return <View row marginB-10>
          <View>
            <CustomTextField placeholder="Agent Name" value={agentName} onChange={(val) => {
              setAgentName(val);
            }} />
          </View>
          <View marginL-10 style={{
            width: 200
          }}>
            <CustomSelect placeholder="Group" value={props.tabFilter.group_id} options={
              (props.createData?.Groups || []).map((group) => ({ ...group, label: group.name, value: group._id }))
            } onChange={(val) => {
              const obj = {
                ...props.tabFilter,
                group_id: val
              };

              if (!val) {
                delete obj.group_id;
              }
              props.setTabFilter(obj)
            }} />
          </View>

          <View marginL-10 style={{
            width: 200
          }}>
            <CustomSelect placeholder="Agency Teams" value={props.tabFilter.agency_team_id} options={
              (props.createData?.AgencyTeams || []).map((group) => ({ ...group, label: group.name, value: group._id }))
            } onChange={(val) => {
              const obj = {
                ...props.tabFilter,
                agency_team_id: val
              };

              if (!val) {
                delete obj.agency_team_id;
              }

              props.setTabFilter(obj)
            }} />
          </View>
        </View>
      },
      customTitle: "Training Attendance KPI By Agents"
    },
    "DailyActivitiesKPIByAgents": {
      showDateRange: true,
      customFilter: (props) => {
        const searchTimeOutRef = useRef(null);
        const [agentName, setAgentName] = useState(props.tabFilter.agent_name || null);

        useEffect(() => {
          if (agentName !== null && agentName != props.tabFilter.agent_name) {
            const obj = {
              ...props.tabFilter,
              agent_name: agentName
            };

            if (!agentName) {
              delete obj.agent_name;
            }

            if (searchTimeOutRef.current) {
              clearTimeout(searchTimeOutRef.current);
            }

            searchTimeOutRef.current = setTimeout(() => {
              props.setTabFilter(obj)
            }, 500);
          }
        }, [agentName])


        return <View row marginB-10>
          <View>
            <CustomTextField placeholder="Agent Name" value={agentName} onChange={(val) => {
              setAgentName(val);
            }} />
          </View>
          <View marginL-10 style={{
            width: 200
          }}>
            <CustomSelect placeholder="Group" value={props.tabFilter.group_id} options={
              (props.createData?.Groups || []).map((group) => ({ ...group, label: group.name, value: group._id }))
            } onChange={(val) => {
              const obj = {
                ...props.tabFilter,
                group_id: val
              };

              if (!val) {
                delete obj.group_id;
              }
              props.setTabFilter(obj)
            }} />
          </View>

          <View marginL-10 style={{
            width: 200
          }}>
            <CustomSelect placeholder="Agency Teams" value={props.tabFilter.agency_team_id} options={
              (props.createData?.AgencyTeams || []).map((group) => ({ ...group, label: group.name, value: group._id }))
            } onChange={(val) => {
              const obj = {
                ...props.tabFilter,
                agency_team_id: val
              };

              if (!val) {
                delete obj.agency_team_id;
              }

              props.setTabFilter(obj)
            }} />
          </View>
        </View>
      },
      customTitle: "Daily Activities KPI By Agents"
    },

    "AgentsLessThan80PercentMeetingAttendance": {
      showDateRange: true,
      customFilter: (props) => {
        const searchTimeOutRef = useRef(null);
        const [agentName, setAgentName] = useState(props.tabFilter.agent_name || null);
        const [minimumAttendance, setMinimumAttendance] = useState(props.tabFilter.minAttendancePercentage || 80);

        useEffect(() => {
          if (agentName !== null && agentName != props.tabFilter.agent_name) {
            const obj = {
              ...props.tabFilter,
              agent_name: agentName
            };

            if (!agentName) {
              delete obj.agent_name;
            }

            if (searchTimeOutRef.current) {
              clearTimeout(searchTimeOutRef.current);
            }

            searchTimeOutRef.current = setTimeout(() => {
              props.setTabFilter(obj)
            }, 500);
          }
        }, [agentName])

        useEffect(() => {
          if (minimumAttendance !== null && minimumAttendance != props.tabFilter.minAttendancePercentage) {
            const obj = {
              ...props.tabFilter,
              minAttendancePercentage: minimumAttendance
            };

            if (searchTimeOutRef.current) {
              clearTimeout(searchTimeOutRef.current);
            }

            searchTimeOutRef.current = setTimeout(() => {
              props.setTabFilter(obj)
            }, 500);
          }
        }, [minimumAttendance]);


        return <View row marginB-10>
          <View row centerV marginR-10>
            <Text marginR-10>Minimum Attendance</Text>
            <View style={{ width: 100 }}>
              <CustomNumberInput placeholder="80" value={minimumAttendance} onChange={(val) => {
                setMinimumAttendance(val);
              }} />
            </View>
          </View>
          <View>
            <CustomTextField placeholder="Agent Name" value={agentName} onChange={(val) => {
              setAgentName(val);
            }} />
          </View>
          <View marginL-10 style={{
            width: 200
          }}>
            <CustomSelect placeholder="Group" value={props.tabFilter.group_id} options={
              (props.createData?.Groups || []).map((group) => ({ ...group, label: group.name, value: group._id }))
            } onChange={(val) => {
              const obj = {
                ...props.tabFilter,
                group_id: val
              };

              if (!val) {
                delete obj.group_id;
              }
              props.setTabFilter(obj)
            }} />
          </View>

          <View marginL-10 style={{
            width: 200
          }}>
            <CustomSelect placeholder="Agency Teams" value={props.tabFilter.agency_team_id} options={
              (props.createData?.AgencyTeams || []).map((group) => ({ ...group, label: group.name, value: group._id }))
            } onChange={(val) => {
              const obj = {
                ...props.tabFilter,
                agency_team_id: val
              };

              if (!val) {
                delete obj.agency_team_id;
              }

              props.setTabFilter(obj)
            }} />
          </View>
        </View>
      },
      customTitle: "Agents Less Than 80% Meeting Attendance"
    },

    "InventoryDetailByItem": {
      showDateRange: true,
    },
    "LocationReport": {
      showDateRange: true,
    },
    "AvailableSerialNumbers": {

    },
    "AvailableBatches": {

    },
    "ItemTransactionReport": {
      showDateRange: true,
    },
    "JournalEntries": {
      showDateRange: true,
    },
    "BalanceSheet": {
      showDateRange: true,
    },
    "Ledger": {
      showDateRange: true,
    },
    "DayBook": {
      showEndDate: true,
    },
    "DayBookByLocation": {
      showEndDate: true,
    },
    "DayBookByAgent": {
      showEndDate: true,
    },
    "PaymentCollectionByAgent": {
      showEndDate: true,
    },
    "AttendanceByDate": {
      showEndDate: true,
    },
    "AttendanceByAgent": {
      showDateRange: true,
    },
    "AgentKPI": {
      showDateRange: true,
    },
    "EmployeeCourseAttendancePercentageSummary": {
      showDateRange: false,
    },
    "EmployeeMeetingAttendancePercentageSummary": {
      showDateRange: false,
      customColumnRender: {
        count: (value, row, column) => {

          return <View left>
            <MeetingDetailsFunction value={value} row={row} column={column} startDate={startDate} endDate={endDate} />
          </View>
        },
      }
    },
    "MeetingAttendanceSummary": {
      showDateRange: false,
    }
  }

  const [quickFilterOptions, setQuickFilterOptions] = useState([]);
  const [quickFilterKey, setQuickFilterKey] = useState(queryParams.quickFilterKey || props.quickFilterKey || null);
  const [quickFilterValue, setQuickFilterValue] = useState(queryParams.quickFilterValue || props.quickFilterValue || null);


  const [name, setName] = useState(null);
  const [loadedOnce, setLoadedOnce] = useState(false);

  const { modalStore: { getOpenModals, setOpenModal }, layoutStore: { isSideBarCollapsed, toggleSideBarCollapsed, getSideBarPosition, setSideBarPosition } } = useStores();

  let moduleName = props.moduleName || route.name || props.route?.params?.name;

  if (Platform.OS != 'web') {
    moduleName = props.route?.params?.name || moduleName;
  }

  const [startDate, setStartDate] = useState(queryParams.startDate ? moment(queryParams.startDate).toDate() : settingsMap[moduleName]?.start_date || dayjs().startOf('month').toDate());
  const [endDate, setEndDate] = useState(queryParams.endDate ? moment(queryParams.endDate).toDate() : settingsMap[moduleName]?.end_date || dayjs().endOf('month').toDate());

  const [quickDateSelector, setQuickDateSelector] = useState(false);

  let quickDateSelectorOptions = [
    { label: "Today", value: "Today" },
    { label: "Yesterday", value: "Yesterday" },
    { label: "This week", value: "This week" },
    { label: "Last week", value: "Last week" },
    { label: "This month", value: "This month" },
    { label: "Last month", value: "Last month" },
    { label: "This year", value: "This year" },
    { label: "Last year", value: "Last year" },
  ];

  const currentMonth = dayjs().month();
  const currentYear = dayjs().year();

  for (let i = 0; i < 24; i++) {
    let month = currentMonth - i;
    let year = currentYear;

    if (month < 0) {
      month = 12 + month;
      year = year - 1;
    }

    quickDateSelectorOptions.push({
      label: dayjs().month(month).year(year).format('MMM YYYY'),
      value: dayjs().month(month).year(year).format('MMM YYYY')
    });
  }

  const [createData, setCreateData] = useState(null);
  const [tabFilter, setTabFilter] = useState({});

  const getModuleData = async (params = null) => {
    setLoading(true);

    let payload = {
      reportName: moduleName,
      page: pageIndex,
      limit: pageSize,
      sortColumn: sortColumn,
      sortDirection: sortDirection,
      filters: selectedFilters,
      search: search,
      groupBy: groupBy,
      startDate: startDate,
      endDate: settingsMap[moduleName] && settingsMap[moduleName].showEndDate ? moment(endDate).format("YYYY-MM-DD") : endDate,
      tabFilter: props.params?.tabFilter ? props.params?.tabFilter : tabFilter,
    };

    console.log("quickFilterKey", quickFilterKey);
    console.log("quickFilterValue", quickFilterValue);

    const quickFilterKeyNew = quickFilterKey
    const quickFilterValueNew = quickFilterValue

    if (quickFilterKeyNew) {
      payload[quickFilterKeyNew] = quickFilterValueNew;
    }

    let res = await api.customRoute("getReport", payload);

    let { data, all_columns = [], selected_columns = [], filters, createData = {} } = res;

    setCreateData(createData);

    setRows(data?.data || []);
    setTotal(data?.total || 0);

    selected_columns = selected_columns.filter((column) => {
      if (!column.field) {
        return false;
      }
      if (column.accessor == "actions") {
        return false;
      }
      column.Header = column.label;
      column.accessor = column.field;

      if (column.width) {
        const width = "" + column.width;
        column.width = width.replace('px', '');
      }

      return true;
    });

    if (selected_columns.length === all_columns.length) {
      selected_columns = selected_columns.filter((col) => col.visible);
    }

    selected_columns.push({ Header: (props) => null, accessor: 'actions', width: 75, minWidth: 75 });

    const allColumns = all_columns.map((col, index) => ({
      ...col,
      id: index,
      visible: selected_columns.find((c) => c.field === col.field) ? true : false,
      width: selected_columns.find((c) => c.field === col.field) ? selected_columns.find((c) => c.field === col.field).width : 150,
    }))

    setAllColumns(allColumns || []);
    setSelectedColumns(selected_columns || []);
    setFilters(filters || []);
    setQuickFilterOptions(res.quickFilterOptions || []);

    if (res.quickFilterKey && !quickFilterKey) {
      setQuickFilterKey(res.quickFilterKey || null);
    }

    setLoading(false);
  }

  const download = async (type) => {
    const payload = {
      reportName: moduleName,
      page: pageIndex,
      limit: pageSize,
      sortColumn: sortColumn,
      sortDirection: sortDirection,
      filters: selectedFilters,
      search: search,
      type: type,
      groupBy: groupBy,
      startDate: startDate,
      endDate: settingsMap[moduleName] && settingsMap[moduleName].showEndDate ? moment(endDate).format("YYYY-MM-DD") : endDate,
      tabFilter: props.params?.tabFilter ? props.params?.tabFilter : tabFilter,
    };

    if (quickFilterKey) {
      payload[quickFilterKey] = quickFilterValue;
    }

    const data = await api.customRoute("getReport", payload);

    api.download(data, type);
  }

  const saveColumns = async (columns, reload = true) => {
    await api.customRoute("saveColumnsReports/" + moduleName, {
      columns: columns,
    });

    if (reload) {
      setTimeout(() => {
        getModuleData();
      }, 0);
    }
  }

  const goBack = () => {
    if (Platform.OS === 'web') {
      window.history.back();
    } else {
      navigation.goBack();
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      await getModuleData();
    };

    fetchData();
  }, [
    search,
    groupBy,
    selectedFilters,
    sortColumn,
    sortDirection,
    pageIndex,
    pageSize,
    startDate,
    endDate,
    quickFilterValue,
    tabFilter
  ]);

  useEffect(() => {
    console.log(window?.location?.pathname);
    console.log(queryParams);

    if (Platform.OS === 'web') {
      if (queryParams.quickFilterKey) {
        setQuickFilterKey(queryParams.quickFilterKey);
      } else {
        setQuickFilterKey(null);
      }
      if (queryParams.quickFilterValue) {
        setQuickFilterValue(queryParams.quickFilterValue);
      } else {
        setQuickFilterValue(null);
      }
      if (queryParams.startDate) {
        setStartDate(moment(queryParams.startDate).toDate());
      }
      if (queryParams.endDate) {
        setEndDate(moment(queryParams.endDate).toDate());
      }
    }
  }, [window?.location?.pathname]);

  useEffect(() => {
    DeviceEventEmitter.addListener("reloadListing", (event) => {
      getModuleData();
    })

    return () => {
      DeviceEventEmitter.removeAllListeners("reloadListing");
    }
  }, []);

  const globalData = GlobalMasterStore((state) => state.globalData);

  useEffect(() => {
    const organization_id = globalData?.organization_id;
    const organizations = globalData?.organizations;
    const organization = organizations?.find((o) => o.id == organization_id);
    setName(organization?.name);
  }, [globalData]);

  const CustomFilter = settingsMap[moduleName].customFilter;

  const customColumnRender = settingsMap[moduleName].customColumnRender;

  const Table = <DynamicTable
    data={rows}
    updateData={(newData) => {
      setRows(newData)
    }}
    columns={selectedColumns}
    allColumns={allColumns}
    hideLabelOnMobile={true}
    cfAdd={true}
    saveColumns={saveColumns}
    isGrouped={groupBy ? true : false}
    mono={props.noMono ? false : true}
    // total={total}
    // pageSize={pageSize}
    // pageIndex={pageIndex}
    // onQueryParams={onQueryParams}
    // showCheckbox={true}
    resizable={true}

    startDate={moment(startDate).format('YYYY-MM-DD')}
    endDate={moment(endDate).format('YYYY-MM-DD')}
    selectedFilters={selectedFilters}
    groupBy={groupBy}
    customColumnRender={customColumnRender}
  />

  const newModule = () => {
    if (Platform.OS === 'web') {
      const query = {}
      const params = window.location.search.substring(1).split('&')
      params.map((param) => {
        const [key, value] = param.split('=')
        if (key) {
          query[key] = value;
        }
      })
      navigationFn('New/' + moduleName, {
        moduleName: moduleName,
        ...query,
        goBackAfterSave: true
      })

    } else {
      navigationFn('New', {
        moduleName: moduleName,
        goBackAfterSave: true
      })
    }
  }

  const readableModuleName = moduleName?.replace(/([A-Z])/g, ' $1').trim();

  const MainWrapper = useCallback(({ children }) => {
    return !props.hideWrapper ? <>
      <LayoutScreen>
        {Platform.OS == 'web' && <View marginT-2 style={{
          flex: 1,
        }} padding-20>
          <Card padding-10 style={{
            height: Dimensions.get('window').height - 40,
          }}>
            {children}
          </Card>
        </View>}

        {Platform.OS != 'web' && <View marginT-2 style={{
          flex: 1,
        }} padding-10>
          {children}
        </View>}

      </LayoutScreen>
    </> : <>
      {children}
    </>
  }, [props.hideWrapper])


  return (
    <MainWrapper>
      {/* <Card padding-10> */}

      <View spread centerV row paddingB-10 marginT-5 marginB-10 style={{
        borderBottomColor: '#0000000d',
        borderBottomWidth: 1,
        width: '100%'
      }}>
        {props.hideDates ? <></> : <View paddingT-10 row spread centerV style={Platform.OS == 'web' ? {} : {
          width: '100%',
          flexWrap: 'wrap'
        }}>
          {settingsMap[moduleName] && settingsMap[moduleName].showDateRange && <>
            {Platform.OS == 'web' && <View>
              <View style={{
                width: Platform.OS == 'web' ? 150 : '100%',
                marginRight: Platform.OS == 'web' ? 10 : 0
              }}>
                <CustomSelect
                  placeholder="Quick Date Selector"
                  value={quickDateSelector}
                  options={quickDateSelectorOptions}
                  onChange={(value) => {
                    setQuickDateSelector(value);
                    if (value === "Today") {
                      setStartDate(dayjs().startOf('day').toDate());
                      setEndDate(dayjs().endOf('day').toDate());
                    } else if (value === "Yesterday") {
                      setStartDate(dayjs().subtract(1, 'day').startOf('day').toDate());
                      setEndDate(dayjs().subtract(1, 'day').endOf('day').toDate());
                    } else if (value === "This week") {
                      setStartDate(dayjs().startOf('week').toDate());
                      setEndDate(dayjs().endOf('week').toDate());
                    } else if (value === "Last week") {
                      setStartDate(dayjs().subtract(1, 'week').startOf('week').toDate());
                      setEndDate(dayjs().subtract(1, 'week').endOf('week').toDate());
                    } else if (value === "This month") {
                      setStartDate(dayjs().startOf('month').toDate());
                      setEndDate(dayjs().endOf('month').toDate());
                    } else if (value === "Last month") {
                      setStartDate(dayjs().subtract(1, 'month').startOf('month').toDate());
                      setEndDate(dayjs().subtract(1, 'month').endOf('month').toDate());
                    } else if (value === "This year") {
                      setStartDate(dayjs().startOf('year').toDate());
                      setEndDate(dayjs().endOf('year').toDate());
                    } else if (value === "Last year") {
                      setStartDate(dayjs().subtract(1, 'year').startOf('year').toDate());
                      setEndDate(dayjs().subtract(1, 'year').endOf('year').toDate());
                    } else {
                      setStartDate(dayjs(value).startOf('month').toDate());
                      setEndDate(dayjs(value).endOf('month').toDate());
                    }
                  }}
                />
              </View>
            </View>}
            <View marginR-10 row spread centerV style={{
              width: Platform.OS == 'web' ? 300 : '100%'
            }}>
              <View marginR-10>
                <CustomDatePicker allowClear={false} placeholder="Start Date" value={startDate} onChange={(e) => {
                  setStartDate(e);
                }} />
              </View>
              <View style={{
                marginTop: Platform.OS == 'web' ? 0 : -8
              }}>
                {/* <Text marginT-10>To</Text> */}
                <Icon name='arrow-forward-outline' width={16} height={16} fill='#53535f' />
              </View>
              <View marginL-10>
                <CustomDatePicker allowClear={false} placeholder="End Date" value={endDate} onChange={(e) => {
                  setEndDate(e);
                }} />
              </View>
            </View>
          </>}

          {settingsMap[moduleName] && settingsMap[moduleName].showEndDate && <View row centerV>
            <View marginL-10>
              <CustomDatePicker placeholder="End Date" value={endDate} onChange={(e) => {
                setEndDate(e);
              }} />
            </View>
          </View>}
        </View>}

        <View row centerV>
          {Platform.OS == 'web' ? (queryParams.hideQuickFilter) ? null : <View style={{
            minWidth: 150,
          }}>
            {quickFilterOptions && quickFilterOptions.length > 0 && <CustomSelect
              label="Quick Filter"
              width={150}
              options={quickFilterOptions}
              value={quickFilterValue}
              onChange={(value) => {
                console.log("value", value);
                setQuickFilterValue(value);
              }}
            />}
          </View> : null}

          {filters.length > 0 && <SecondaryButton onPress={() => {
            setShowFilter(true);
          }} style={{ marginLeft: 10 }}>
            <View row centerV>
              <Icon name='options-2-outline' width={16} height={16} fill='#53535f' />
              <View marginL-5>
                <Text>Filter</Text>
              </View>
            </View>
          </SecondaryButton>}

          {/* <SecondaryButton onPress={() => {
            setGroupByModal(true);
          }} style={{ marginLeft: 10 }}>
            <View row centerV>
              <Icon name='folder-outline' width={16} height={16} fill='#53535f' />
              <View marginL-5>
                <Text>Group By</Text>
              </View>
            </View>
          </SecondaryButton> */}

          {Platform.OS == 'web' ? <Dropdown
            menu={{
              items: [{
                label: 'Excel',
                onClick: () => {
                  download('excel');
                }
              }, {
                label: 'PDF',
                onClick: () => {
                  download('pdf');
                }
              }]
            }}

          >
            <View>
              <SecondaryButton onPress={(e) => {
                e?.preventDefault();
              }} style={{ marginLeft: 10 }}>
                <View row centerV>
                  <Icon name='cloud-download-outline' width={16} height={16} fill='#53535f' />
                  <View marginL-5>
                    <Text>Export</Text>
                  </View>
                </View>
              </SecondaryButton>
            </View>

          </Dropdown> :
            null}

          {!props.hideHeader && Platform.OS == 'web' && <SecondaryButton onPress={() => {
            goBack()
          }} style={{ marginLeft: 10 }} >
            <Icon name='close-outline' width={16} height={16} fill='#53535f' />
          </SecondaryButton>}
        </View>
      </View>

      {settingsMap[moduleName] && settingsMap[moduleName].customFilter && <View>
        {CustomFilter && <CustomFilter tabFilter={tabFilter} setTabFilter={setTabFilter} createData={createData} />}
      </View>}



      <View>
        {Platform.OS != 'web' ? queryParams.hideQuickFilter ? null : <View style={{
          minWidth: 150
        }}>
          {quickFilterOptions && quickFilterOptions.length > 0 && <CustomSelect
            label="Quick Filter"
            width={150}
            options={quickFilterOptions}
            value={quickFilterValue}
            onChange={(value) => {
              console.log("value", value);
              setQuickFilterValue(value);
            }}
          />}
        </View> : null}
      </View>

      {
        !props.hideHeader && <View row center marginB-20>
          <View center>
            <Text formlabel monoBold> {name} </Text>
            <Text formlabel monoRegular> {settingsMap[moduleName].customTitle || readableModuleName} </Text>
            {settingsMap[moduleName] && settingsMap[moduleName].showDateRange && <Text formlabel monoRegular> FROM {dayjs(startDate).format('DD MMM YYYY')} TO {dayjs(endDate).format('DD MMM YYYY')} </Text>}

            {settingsMap[moduleName] && settingsMap[moduleName].showEndDate && <Text formlabel monoRegular> AS ON {dayjs(endDate).format('DD MMM YYYY')} </Text>}

            {!(settingsMap[moduleName] && settingsMap[moduleName].showDateRange) && !(settingsMap[moduleName] && settingsMap[moduleName].showEndDate) && <Text formlabel monoRegular> AS ON {dayjs().format('DD MMM YYYY')} </Text>}
          </View>
        </View>
      }

      <View left flex-5 flexG>
        <View flex style={{ width: '100%' }}>
          {!loading ? Table : <Text>Loading...</Text>}
        </View>
      </View>

      <CustomModal
        title="Apply FIlters"
        visible={showFilter}
        onClose={() => setShowFilter(false)}
      >
        <FiltersComponent
          createData={createData}
          filters={filters}
          selectedFilters={selectedFilters}
          visible={showFilter}
          updateSelectedFilters={(filters) => {
            setSelectedFilters(filters);
            setShowFilter(false);
          }} />
      </CustomModal>

      <CustomModal
        drawer
        title="Group By"
        visible={groupByModal}
        onClose={() => {
          setGroupByModal(false);
        }}
      >
        <View flex useSafeArea>

          <TouchableOpacity
            onPress={() => {
              setGroupByModal(false);
              setGroupBy(null);
            }}
          >
            <View row style={{ cursor: 'grab' }}>
              <Image assetName="drag" style={{ width: 20, height: 20, opacity: 0.2 }} />
              <Text text-90N grey10>
                None
              </Text>
            </View>
          </TouchableOpacity>

          {allColumns.map((item, index) => (
            <TouchableOpacity
              key={index}
              onPress={() => {
                setGroupByModal(false);
                setGroupBy(item.field);
              }}
            >
              <View row style={{ cursor: 'grab' }}>
                <Image assetName="drag" style={{ width: 20, height: 20, opacity: 0.2 }} />
                <Text text-90N grey10 key={item.field}>
                  {item.label}
                </Text>
              </View>
            </TouchableOpacity>
          ))}
        </View>
      </CustomModal>

      <ActionSheet
        useNativeIOS
        visible={showExport}
        title={'Title'}
        message={'Message goes here'}
        cancelButtonIndex={2}
        onDismiss={() => setShowExport(false)}
        options={[
          { label: 'Excel', onPress: () => download('excel') },
          { label: 'PDF', onPress: () => download('pdf') },
          { label: 'Cancel', onPress: () => setShowExport(false) }
        ]}
      />
    </MainWrapper >
  )
}

export default ReportScreenSingle

const $container = {
  flex: 1,
}